import { attachmentApi } from '@apis/attachmentApi';
import { createCancelToken } from '@apis/ServiceController';
import SEO from '@components/SEO';
import IconAdd2 from '@icons/IconAdd2';
import IconDownload from '@icons/IconDownload';
import { convertToDate } from '@utils/common';
import constant from '@utils/constant';
import { AttachmentType } from '@utils/enums/AttachmentType';
import { downloadFileFromUrlString, getFileExtensionByUrlString } from '@utils/fileUtils';
import CommonTable from '@views_admin/components/CommonTable';
import ContentLoading from '@views_admin/ContentLoading';
import Search from '@views_admin/layout/Search';
import BtnAddShippingFee from '@views_admin/shipment/BtnAddShippingFee';
import { Tooltip } from 'antd';
import { Link } from 'gatsby';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

const Fees = memo(() => {
   const [searchText, setSearchText] = useState('');
   const [filter, setFilter] = useState({
      page: 1,
      page_size: constant.defaultPagesize,
      type: AttachmentType.SHIPPING_FEES,
   });
   const [data, setData] = useState({
      content: [],
      totalRows: 0,
   });
   const [loading, setLoading] = useState(false);
   const cancelToken = useRef();

   useEffect(() => {
      const loadData = async () => {
         if (cancelToken.current) {
            cancelToken.current.cancel();
         }
         await setLoading(true);
         cancelToken.current = createCancelToken();
         const res = await attachmentApi.getAttachmentsMatrix(
            { ...filter, search: searchText.trim() },
            cancelToken.current?.token
         );
         if (res.isSuccess) {
            const { content, totalRows } = res.data;
            setData((prevData) => ({
               ...prevData,
               content: content || [],
               totalRows: totalRows,
            }));
         }
         setSearchText(searchText.trim());
         setLoading(false);
      };

      loadData();

      return () => {
         if (cancelToken.current) {
            cancelToken.current.cancel();
         }
      };
   }, [filter, searchText]);

   const columns = useMemo(() => {
      return [
         {
            title: 'File name',
            dataIndex: 'name',
         },
         {
            title: 'Author',
            dataIndex: 'author_username',
         },
         {
            title: 'Created date',
            dataIndex: 'created_at',
            render: (text) => <span>{text ? convertToDate(text) : ''}</span>,
         },
         {
            title: 'Action',
            key: 'action',
            width: 116,
            render: (text, record) => (
               <div className="admin-col-action">
                  <Tooltip placement="bottom" title="Download" color="black">
                     <button
                        className="button-icon action-normal"
                        onClick={() => downloadFileFromUrlString(record.name, record.url)}
                     >
                        <IconDownload />
                     </button>
                  </Tooltip>
               </div>
            ),
         },
      ];
   }, []);

   const reloadData = useCallback(() => {
      setFilter((prevFilter) => ({
         ...prevFilter,
      }));
   }, []);

   const onTableChange = useCallback(({ pagination, sorter }) => {
      setFilter((prevFilter) => ({
         ...prevFilter,
         page: pagination?.page || prevFilter.page,
         page_size: pagination?.pageSize || prevFilter.page_size,
      }));
   }, []);

   const handleAddFeeSuccess = useCallback(() => {
      reloadData();
   }, [reloadData]);

   return (
      <>
         <SEO title={'Shipping Fees - Admin'} />
         <div className="content-head">
            <div className="content-head__left">
               <div className="admin-shipment__tabs">
                  <Link className={`tab-item`} to={`${constant.ROUTE_ADMIN_SHIPMENT}`}>
                     Orders
                  </Link>
                  <div className={`tab-item active`}>Shipping Fees</div>
               </div>
            </div>
            <div className="content-head__right">
               <BtnAddShippingFee
                  className="outline-btn btn-add-new-item"
                  onSuccess={handleAddFeeSuccess}
               >
                  <i>
                     <IconAdd2 />
                  </i>
                  Add Shipping Fee
               </BtnAddShippingFee>
            </div>
         </div>
         <div className="content-body admin-products__body">
            <div className="admin-table">
               <ContentLoading isLoading={loading} />
               <div className="admin-table__header">
                  <div className="admin-table__header--left">
                     <Search placeholder="Search..." onSearch={(v) => setSearchText(v)} />
                  </div>
                  <div className="admin-table__header--right">
                     {/* <Filter filterSet={filterSet} onChange={handleFilter} /> */}
                  </div>
               </div>
               <CommonTable
                  rowKey="uid"
                  unit="Shipping Fees"
                  dataSource={data.content}
                  columns={columns}
                  page={filter.page}
                  pageSize={filter.page_size}
                  totalRows={data.totalRows}
                  onChange={onTableChange}
               />
            </div>
         </div>
      </>
   );
});

export default Fees;
