import { adminService } from '../services/adminService';
import { tokenUtil } from '../utils/token';
import { apiConstant } from './constant';
import { fetch } from './ServiceController';

const getAttachmentsMatrix = async (body, cancelToken) => {
   return fetch({
      method: 'post',
      url: apiConstant.GET_ATTACHMENTS_MATRIX,
      data: body,
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
      errorHandler: adminService.handleError,
   });
};

const uploadAttachments = async (body, cancelToken, onUploadProgress) => {
   const formData = new FormData();
   const { files, fileType, uid, username } = body || {};
   files.forEach((f) => {
      formData.append('files', f);
   });
   if (fileType) {
      formData.append('file_type', fileType);
   }
   if (username) {
      formData.append('username', username);
   }
   if (uid) {
      formData.append('uid', uid);
   }
   return fetch({
      method: 'post',
      url: apiConstant.UPLOAD_ATTACHMENTS,
      data: formData,
      headers: {
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
      onUploadProgress,
      errorHandler: adminService.handleError,
   });
};

const getAttachmentByUid = async (uid, cancelToken) => {
   return fetch({
      method: 'get',
      url: apiConstant.GET_ATTACHMENT_BY_UID,
      params: {
         uid,
      },
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
      errorHandler: adminService.handleError,
   });
};

const deleteAttachments = async (uids, cancelToken) => {
   return fetch({
      method: 'delete',
      url: apiConstant.DELETE_ATTACHMENTS,
      data: {
         uids: uids,
      },
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
      errorHandler: adminService.handleError,
   });
};

export const attachmentApi = {
   getAttachmentsMatrix,
   uploadAttachments,
   getAttachmentByUid,
   deleteAttachments,
};
