import { tokenUtil } from '@utils/token';
import { apiConstant } from '@apis/constant';
import { fetch } from '@apis/ServiceController';
import { adminService } from './adminService';

const getAllShippingFees = async (body, cancelToken) => {
   return fetch({
      method: 'post',
      url: apiConstant.GET_ALL_SHIPPING_FEES,
      data: body,
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
   });
};

const getShipmentMatrix = async (body, cancelToken) => {
   return fetch({
      method: 'post',
      url: apiConstant.GET_SHIPMENT_MATRIX,
      data: body,
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
   });
};

const shipOrder = async (body, cancelToken) => {
   return fetch({
      method: 'put',
      url: apiConstant.SHIP_ORDER,
      data: body,
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
   });
};

const confirmToReceiveOrder = async (body, cancelToken) => {
   return fetch({
      method: 'put',
      url: apiConstant.CONFIRM_TO_RECEIVE_ORDER,
      data: body,
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
   });
};

const cancelOrder = async (body, cancelToken) => {
   return fetch({
      method: 'put',
      url: apiConstant.CANCEL_ORDER,
      data: body,
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
   });
};

const importShippingFee = async (body, cancelToken) => {
   const formData = new FormData();
   formData.append('file', body.file);
   formData.append('vat', body.vat);
   return fetch({
      method: 'post',
      url: apiConstant.IMPORT_FEE,
      data: formData,
      headers: {
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
      errorHandler: adminService.handleError,
   });
};

export const shippingService = {
   getAllShippingFees,
   getShipmentMatrix,
   shipOrder,
   confirmToReceiveOrder,
   cancelOrder,
   importShippingFee,
};
