import React from 'react';

const IconDownload = () => (
   <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         d="M10.959 5.93335C13.359 6.14002 14.339 7.37335 14.339 10.0733V10.16C14.339 13.14 13.1457 14.3334 10.1657 14.3334H5.82568C2.84568 14.3334 1.65234 13.14 1.65234 10.16V10.0733C1.65234 7.39335 2.61901 6.16002 4.97901 5.94002"
         stroke="currentColor"
         strokeLinecap="round"
         strokeLinejoin="round"
      />
      <path
         d="M8 1.33325V9.91992"
         stroke="currentColor"
         strokeLinecap="round"
         strokeLinejoin="round"
      />
      <path
         d="M10.2342 8.43335L8.00091 10.6667L5.76758 8.43335"
         stroke="currentColor"
         strokeLinecap="round"
         strokeLinejoin="round"
      />
   </svg>
);

export default IconDownload;
